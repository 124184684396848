/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NhanVien from "views/Staff"
import CapNhap from "views/Update"
import Plugin from "views/Plugin"
import Dll from "views/Dll"
import NgonNgu from "views/Language"
import Deco from "../security/deco"

import routes from "routes.js";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      newRoutes: []
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
    this.logged()
    this.administratorPrivileges()
    // this.getToolFromServer()
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  administratorPrivileges() {
    const user = JSON.parse(Deco(localStorage.getItem('user')));
    if (user.role == "ADMIN") {
      routes.push({
        path: "/staff",
        name: "Staff",
        icon: "nc-icon nc-badge",
        component: NhanVien,
        layout: "/admin",
      })
    }

    routes.push({
      path: "/language",
      name: "Language",
      icon: "nc-icon nc-box",
      component: NgonNgu,
      layout: "/admin",
    })

    routes.push({
      path: "/update",
      name: "Update",
      icon: "nc-icon nc-box",
      component: CapNhap,
      layout: "/admin",
    })

    routes.push({
      path: "/plugin",
      name: "Plugin",
      icon: "nc-icon nc-box",
      component: Plugin,
      layout: "/admin",
    })

    routes.push({
      path: "/dll",
      name: "Dll",
      icon: "nc-icon nc-box",
      component: Dll,
      layout: "/admin",
    })

    // routes.push({
    //   path: "/download",
    //   name: "Download",
    //   icon: "nc-icon nc-box",
    //   component: CapNhap,
    //   layout: "/admin",
    // })

    this.setState({ newRoutes: routes})
  }

  logged() {
    try {
      const user = JSON.parse(Deco(localStorage.getItem('user')));
      if (user == '') {
        window.location = "/admin/login"
        return
      }

      var dateNow = new Date();
      var decodedToken = parseJwt(user.token)



      if (decodedToken.exp < dateNow.getTime() / 1000) {
        window.location = "/admin/login"
        return
      }

    } catch (error) {
      window.location = "/admin/login"
      return
    }

    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    };
  }

  getTool() {
    const queryPath = window.location.pathname;
    return queryPath.replace(/admin/gi, '').replace(/[^a-zA-Z0-9 ]/g, '')
  }

  getBrand(arr) {
    let brandName = ''
    arr.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName
  }

  render() {
    const { newRoutes } = this.state
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={newRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar title={this.getBrand(newRoutes)} {...this.props} />
          <Switch>
            {newRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          {/* <Footer fluid /> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;
