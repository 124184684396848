import React, { useState, useEffect } from 'react'
import {
	Table,
	Input,
	Button,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	Col,
	Row,
	Card,
	CardFooter,
	CardBody,
	Label,
	FormGroup,
	CardHeader,
	CardTitle
} from "reactstrap";
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Select from 'react-select';

import updateService from 'services/update.service'
import toolService from 'services/tool.service'

function Orders() {
	const [lstTool, setLstTool] = useState([])
	const [tool, setTool] = useState('')
	const [toolName, setToolName] = useState('')
	const [dll, setDll] = useState([])

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const [id, setId] = useState('')
	const [typeFile, setTypeFile] = useState('Tải lên file')

	const [localPath, setLocalPath] = useState('')

	const [lstFolder, setLstFolder] = useState([])
	const [folderSelected, setFolderSelected] = useState('')

	const [lstFile, setLstFile] = useState([])
	const [fileSelected, setFileSelected] = useState('')

	const [version, setVersion] = useState('file')
	const [log, setLog] = useState('')

	const [selectedFile, setSelectedFile] = useState('')

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'btn btn-danger'
		},
		buttonsStyling: false
	})

	useEffect(() => {
		toolService.getToolDatabase().then((result) => {
			setLstTool(result.data)

			loadDll(result.data[0].matool)
			setToolName(result.data[0].name)
		})
	}, [])

	function loadDll(tool) {
		setTool(tool)

		updateService.getDll(tool).then((result) => {
			console.log(result.data.data)
			setDll(result.data.data)
		})
	}

	async function postDll() {
		var file = selectedFile
		if (typeFile == "Chọn từ server") {
			file = folderSelected + "/" + fileSelected
			setSelectedFile(file)
		}

		var result = await updateService.postDll(tool, typeFile, localPath, file)

		if (result.successCount > 0) {
			// Nếu thành công thì lấy lại dữ liệu từ server
			updateService.getDll(tool).then((resultRequest) => {
				setDll(resultRequest.data.data)
			})
		}

		Swal.fire({
			icon: result.successCount == 2 ? 'success' : result.successCount == 1 ? 'warning' : 'error',
			title: result.resultCurrent.data.message,
			text: `${process.env.REACT_APP_MY_HOST} tải lên ${result.resultCurrent.data.success ? 'thành công' : 'thất bại'}\n${process.env.REACT_APP_FORWARD_HOST} tải lên ${result.resultForward.data.success ? 'thành công' : 'thất bại'}`,
			showConfirmButton: true,
			timer: 1000
		})
	}

	function table() {
		return (
			<Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
				<thead className="text-primary">
					<tr>
						<th className="table-device-mobile">Server Path</th>
						<th className="table-device-mobile">Local Path</th>
						<th className="table-device-mobile">Updated At</th>
						<th className='text-center'>Delete</th>
					</tr>
				</thead>
				<tbody>
					{loadFillData()}
				</tbody>
			</Table>
		)
	}

	function loadFillData() {
		return dll.map((data, key) => {
			return (
				<tr key={key}>
					<td className="table-device-mobile">{data.url_v2}</td>
					<td className="table-device-mobile">{data.path}</td>
					{/* <td className="table-device-mobile">{data.md5}</td> */}
					<td className="table-device-mobile"> {moment(data.createdAt).format('DD/MM/yyyy HH:mm')} </td>

					<td className='text-center'>
						<Button style={{ marginLeft: "10px" }} type="button" className="btn btn-danger " onClick={() => {

							Swal.fire({
								title: 'Xác nhận xóa ?',
								text: `Điều này sẽ xóa bản ghi ${data.path} phần mềm ${toolName}`,
								icon: 'warning',
								showCancelButton: true,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: 'Có, xóa bản ghi!'
							}).then((result) => {
								if (result.isConfirmed) {

									updateService.deleteDll(data.tool, data.id, data.url_v2).then((result) => {
										if (result.data.success) {
											updateService.getDll(data.tool).then((result) => {
												setDll(result.data.data)
											})
										}

										Swal.fire({
											icon: result.data.success ? 'success' : 'error',
											title: result.data.message,
											showConfirmButton: true,
											timer: 1000
										})
									})
								}
							})
						}}>Xóa</Button>
					</td>
				</tr>
			)
		})
	};

	return (
		<div className="content">
			<Row>
				<Col md="9">
					<div className="card">
						<div className="card-header">
							<div className="p-2">
								<Dropdown isOpen={dropdownOpen} toggle={toggle} >
									<DropdownToggle className="btn-secondary" caret>{toolName}</DropdownToggle>
									<DropdownMenu >
										{
											lstTool.map((data, key) => {
												return (
													<DropdownItem onClick={() => { loadDll(data.matool); setToolName(data.name) }} key={key}>{data.name}</DropdownItem>
												)
											})
										}

										<DropdownItem onClick={() => { loadDll('shared'); setToolName("Shared") }} >Shared</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>

						<div className="card-body">
							{table()}
						</div>
					</div>
				</Col>

				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle tag="h5">Thêm bảng ghi</CardTitle>
						</CardHeader>
						<CardBody>
							<FormGroup>
								<Label>Nguồn dữ liệu</Label>
								<Select
									placeholder={typeFile}
									value={typeFile}
									onChange={(e) => {
										setTypeFile(e.value)

										if (e.value == "Chọn từ server") {
											updateService.getExplorerFiles("").then((result) => {
												var result = result.data.data
												var lst = []
												for (let i = 0; i < result.length; i++) {
													lst.push({ value: result[i], label: result[i] });
												}

												setLstFolder(lst)
												setFolderSelected("./update/shared/dll")

												updateService.getExplorerFiles("./update/shared/dll").then((result) => {
													var result = result.data.data
													var lst = []
													for (let i = 0; i < result.length; i++) {
														lst.push({ value: result[i], label: result[i] });
													}

													setLstFile(lst)
													setFileSelected("")
												})
											})
										}
									}}
									options={
										[
											{ value: 'Tải lên file', label: 'Tải lên file' },
											{ value: 'Chọn từ server', label: 'Chọn từ server' }
										]
									}
								/>

								{
									typeFile == 'Tải lên file'
										?
										<>
											<Label style={{ marginTop: "1rem" }}>Local Path</Label>
											<Input style={{ maxHeight: "100%" }}
												value={localPath}
												id="localPath"
												onChange={(e) => { setLocalPath(e.target.value) }}
											/>

											<Label style={{ marginTop: "1rem" }}>File</Label>
											<Input
												type="file"
												id="file"
												onChange={(e) => { setSelectedFile(e.target.files[0]) }}
											/>
										</>
										:
										<>
											<div style={{ paddingTop: "15px" }}>
												<Label>Thư mục</Label>
												<Select
													placeholder={folderSelected}
													value={folderSelected}
													onChange={(e) => {
														setFolderSelected(e.value)

														updateService.getExplorerFiles(e.value).then((result) => {
															var result = result.data.data
															var lst = []
															for (let i = 0; i < result.length; i++) {
																lst.push({ value: result[i], label: result[i] });
															}

															setLstFile(lst)
															setFileSelected("")
														})
													}}
													options={lstFolder}
												/>
											</div>

											<div style={{ paddingTop: "15px" }}>
												<Label>Tập tin</Label>
												<Select
													placeholder={fileSelected}
													value={fileSelected}
													onChange={(e) => {
														setFileSelected(e.value)
													}}
													options={lstFile}
												/>
											</div>

											<Label style={{ marginTop: "1rem" }}>Local Path</Label>
											<Input style={{ maxHeight: "100%" }}
												value={localPath}
												id="localPath"
												onChange={(e) => { setLocalPath(e.target.value) }}
											/>
										</>
								}
							</FormGroup>
						</CardBody>

						<CardFooter>
							<Button
								// disabled={!(localPath && selectedFile)}
								type="button"
								className="btn btn-success"
								onClick={postDll}
							>
								Thêm mới
							</Button>
						</CardFooter>
					</Card>
				</Col>
			</Row>

		</div>
	)
}

export default Orders