import React, { useState } from "react"
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";
import MembersService from "services/members.service"

import moment from 'moment'

function MemberDetail(props) {
    const data = props.data
    const [id, setId] = useState(data.id || '')
    const [role, setRole] = useState(data.role || '')
    const [account, setAccount] = useState(data.username || '')
    const [email, setEmail] = useState(data.email || '')
    const [fullName, setFullName] = useState(data.fullname || '')
    const [password, setPassword] = useState('')
    const [createdAt, setCreatedAt] = useState(moment(data.createdAt).format('D/M/yyyy') || '')
    const [updatedAt, setUpdatedAt] = useState(moment(data.updatedAt).format('D/M/yyyy') || '')
    const [passwordEncryption, setPasswordEncryption] = useState(data.password || '')

    function deleteMember() {
        if (role == 'ADMIN') {
            Swal.fire({
                title: 'Thông báo',
                text: 'Bạn không thể xóa tài khoản admin',
                type: 'message',
            })
        } else {
            Swal.fire({
                title: 'Xác nhận',
                text: 'Bạn có chắc chắn muốn xóa tài khoản này ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
            }).then((result) => {
                if (result.value) {
                    MembersService.deleteMemberByMemberId(id)
                        .then(response => {
                            Swal.fire({
                                title: 'Thông báo',
                                text: response.data.message,
                                type: 'message',
                            }).then(res => {
                                res.isConfirmed ? window.location.reload() : window.location.reload()
                            })
                        }, error => {
                            Swal.fire({
                                title: 'Thông báo',
                                text: error.message,
                                type: 'error',
                            })
                        })
                };
            })
        }
    }

    function updateMember() {
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn thay đổi thông tin tài khoản này ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                MembersService.updateMemberByMemberId(id, memberInformationWillUpdate())
                    .then(response => {
                        Swal.fire({
                            title: 'Thông báo',
                            text: response.data.message,
                            type: 'message',
                        }).then(res => {
                            res.isConfirmed ? window.location.reload() : window.location.reload()
                        })
                    }, error => {
                        Swal.fire({
                            title: 'Thông báo',
                            text: error.message,
                            type: 'error',
                        })
                    })
            };
        })
    }

    function memberInformationWillUpdate() {
        return {
            "username": account,
            "password": changePassword(password),
            "email": email,
            "fullname": fullName,
        }
    }

    function changePassword(pass){      
        return pass == '' ? passwordEncryption : pass
    }

    return (
        <div className="content">
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}></div>
                <button className="btn btn-primary" onClick={() => deleteMember()} >Xóa</button>
                <button className="btn btn-primary" onClick={() => updateMember()}>Sửa</button>
            </div>
            <FormGroup>
                <Label for="id">User id</Label>
                <Input
                    value={id}
                    id="id"
                    disabled={true}
                    onChange={(e) => setId(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="role">Role</Label>
                <Input
                    value={role}
                    id="role"
                    disabled={true}
                    onChange={(e) => setRole(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="account">Account</Label>
                <Input
                    value={account}
                    id="account"
                    onChange={(e) => setAccount(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="password">Password</Label>
                <Input
                    value={password}
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="email">Email</Label>
                <Input
                    value={email}
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="fullname">Full name</Label>
                <Input
                    value={fullName}
                    id="fullname"
                    onChange={(e) => setFullName(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="createdAt">CreatedAt</Label>
                <Input
                    value={createdAt}
                    id="createdAt"
                    disabled={true}
                    onChange={(e) => setCreatedAt(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="updatedAt">UpdatedAt</Label>
                <Input
                    value={updatedAt}
                    id="updatedAt"
                    disabled={true}
                    onChange={(e) => setUpdatedAt(e.target.value)}
                />
            </FormGroup>
        </div>
    )
}

export default MemberDetail