import axios from 'axios';
import Deco from "security/deco"
import authHeader from './auth-header';

const fs = require('fs').promises;

const API_URL_V3 = process.env.REACT_APP_MY_HOST + "/admin/v3";
const FORWARD_API_URL_V3 = process.env.REACT_APP_FORWARD_HOST + "/admin/v3";

class UpdateService {

	getUpdate(tool) {
		return axios.get(`${API_URL_V3}/update/${tool}`, { headers: authHeader() });
	}

	postUpdate(tool, version, log, selectedFile) {
		var formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("version", version);
		formData.append("log", log);

		//Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')))
		if (user && user.token) {
			try {
				const headers = {
					"Content-Type": "multipart/form-data",
					"Authorization": 'Bearer ' + user.token
				};

				//Request về server còn lại để tạo file
				axios.post(`${FORWARD_API_URL_V3}/update/${tool}`, formData, { headers });

				return axios.post(`${API_URL_V3}/update/${tool}`, formData, { headers });

			} catch (error) {
				console.log(error)
			}
		}
	}

	putUpdate(id, tool, version, log, selectedFile) {
		if (selectedFile) {
			var fs = require('fs').promises;
			var formData = new FormData();
			formData.append("file", selectedFile);
			formData.append("version", version);
			formData.append("log", log);

			//Authen
			const user = JSON.parse(Deco(localStorage.getItem('user')))
			if (user && user.token) {
				try {
					//Request về server còn lại để sửa file
					axios({
						method: "put",
						url: `${FORWARD_API_URL_V3}/update/${tool}/${id}`,
						data: formData,
						headers: {
							"Content-Type": "multipart/form-data",
							"Authorization": 'Bearer ' + user.token
						}
					});

					return axios({
						method: "put",
						url: `${API_URL_V3}/update/${tool}/${id}`,
						data: formData,
						headers: {
							"Content-Type": "multipart/form-data",
							"Authorization": 'Bearer ' + user.token
						}
					});

				} catch (error) {
					console.log(error)
				}
			}
		} else {
			var data = {
				method: "put",
				url: API_URL_V3 + tool + '/' + id,
				data: {
					version: version,
					log: log
				},
				headers: authHeader()
			}

			return axios(data);
		}
	}

	deleteUpdate(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${API_URL_V3}/update/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL_V3}/update/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}


	// dev.dhbtools.com (plugin)
	getPlugin(tool) {
		console.log(tool)
		return axios.get(`${API_URL_V3}/plugin/${tool}`, { headers: authHeader() });
	}

	postPlugin(tool, type, localPath, selectedFile) {
		var formData
		var contentType

		if (type == "Chọn từ server") {
			formData = {
				type: type,
				file: selectedFile,
				localPath: localPath
			}
			contentType = "application/json"
		} else {
			var fs = require('fs').promises;
			formData = new FormData();
			formData.append("type", type)
			formData.append("file", selectedFile);
			formData.append("localPath", localPath);
			contentType = "multipart/form-data"
		}

		console.log(formData)
		console.log(contentType)

		//Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')))
		if (user && user.token) {
			try {
				//Request về server còn lại để tạo file
				// axios({
				// 	method: "post",
				// 	url: `${FORWARD_API_URL_V3}/plugin/${tool}`,
				// 	data: formData,
				// 	headers: {
				// 		"Content-Type": contentType,
				// 		"Authorization": 'Bearer ' + user.token
				// 	}
				// });

				return axios({
					method: "post",
					url: `${API_URL_V3}/plugin/${tool}`,
					data: formData,
					headers: {
						"Content-Type": contentType,
						"Authorization": 'Bearer ' + user.token
					}
				});

			} catch (error) {
				console.log(error)
			}
		}
	}

	deletePlugin(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${FORWARD_API_URL_V3}/plugin/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL_V3}/plugin/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}


	// dev.dhbtools.com (dll)
	getDll(tool) {
		return axios.get(`${API_URL_V3}/dll/${tool}`, { headers: authHeader() });
	}

	async postDll(tool, type, localPath, selectedFile) {
		var formData
		var contentType

		if (type == "Chọn từ server") {
			formData = {
				type: type,
				file: selectedFile,
				localPath: localPath
			}
			contentType = "application/json"
		} else {
			var fs = require('fs').promises;
			formData = new FormData();
			formData.append("type", type)
			formData.append("file", selectedFile);
			formData.append("localPath", localPath);
			contentType = "multipart/form-data"
		}

		//Authen
		const user = JSON.parse(Deco(localStorage.getItem('user')))
		if (user && user.token) {
			try {

				//Request về server còn lại để tạo file
				var resultForward = await axios({
					method: "post",
					url: `${FORWARD_API_URL_V3}/dll/${tool}`,
					data: formData,
					headers: {
						"Content-Type": contentType,
						"Authorization": 'Bearer ' + user.token
					}
				});

				//Request về server đang chạy để tạo file
				var resultCurrent = await axios({
					method: "post",
					url: `${API_URL_V3}/dll/${tool}`,
					data: formData,
					headers: {
						"Content-Type": contentType,
						"Authorization": 'Bearer ' + user.token
					}
				});

				var successCount = 0
				if (resultForward.data.success) {
					successCount++
				}
				if (resultCurrent.data.success) {
					successCount++
				}

				return {
					successCount: successCount,
					resultForward: resultForward,
					resultCurrent: resultCurrent,
				}

			} catch (error) {
				console.log(error)
			}
		}
	}

	deleteDll(tool, id, filename) {
		//Request về server còn lại để xóa file
		axios.delete(`${FORWARD_API_URL_V3}/dll/${tool}/${id}?filename=${filename}`, { headers: authHeader() });

		return axios.delete(`${API_URL_V3}/dll/${tool}/${id}?filename=${filename}`, { headers: authHeader() });
	}


	// dev.dhbtools.com (explorer)
	getExplorerFiles(folder) {
		return axios.get(`${API_URL_V3}/explorer?folder=${folder}`, { headers: authHeader() });
	}
}

export default new UpdateService();
