import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input
} from "reactstrap";
import MembersService from "../services/staff.service"
import DHBCreateMember from "../components/Childrens/DHBCreateMembers"
import DHBMemberDetails from "../components/Childrens/DHBMemberDetails"
import Select from 'react-select';

function DonHang() {
    const [listMembers, setListMembers] = useState([])
    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])
    const [selectFilter, setSelectFilter] = useState("TÀI KHOẢN")
    const [filter, setFilter] = useState("")
    const [listDataFilter, setListDataFilter] = useState([])

    function optionsFilter() {
        return [
            { value: 'ID', label: 'ID' },
            { value: 'TÀI KHOẢN', label: 'TÀI KHOẢN' },
            { value: 'EMAIL', label: 'EMAIL' },
            { value: 'HỌ TÊN', label: 'HỌ TÊN' },
        ]
    }

    useEffect(() => {
        MembersService.getMembers().then((result) => {
            setListMembers(result.data)
            setListDataFilter(result.data)
        })
    }, [])

    function table() {
        return (
            <Col md="12">
                <Card className="card-plain">
                    <CardBody>
                        <Table responsive>
                            <thead className="text-primary">
                                <tr>
                                    <th className="table-device-mobile">ID</th>
                                    <th>Tài khoản</th>
                                    <th className="table-device-mobile">Email</th>
                                    <th>Họ tên</th>
                                    <th>Quyền</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadFillData()}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    function toDetailView(val) {
        setPage('detail')
        setDataProps(val)
    }

    function loadFillData() {
        return listMembers.map((data, key) => {
            return (
                <tr onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <th className="table-device-mobile">{data.id}</th>
                    <td>{data.username}</td>
                    <td className="table-device-mobile">{data.email}</td>
                    <td>{data.fullname}</td>
                    <td>{data.role}</td>
                </tr>
            )
        })
    };

    function back() {
        return (
            <div
                style={{ fontWeight: "bold", cursor: "pointer", marginBottom: "1rem", width: "4rem" }}
                onClick={() => setPage('')}>
                Quay lại
            </div>
        )
    }

    function filterr() {
        let result
        if (selectFilter == "TÀI KHOẢN") {
            result = listDataFilter.filter(word => serach(word.username, filter));
        } else if (selectFilter == "EMAIL") {
            result = listDataFilter.filter(word => serach(word.email, filter));
        } else if (selectFilter == "HỌ TÊN") {
            result = listDataFilter.filter(word => serach(word.fullname, filter));
        } else if (selectFilter == "ID") {
            result = listDataFilter.filter(word => serach(word.id.toString(), filter));
        }
        setListMembers(result)
    }

    function serach(value, key) {
        try {
            return value.toLowerCase().search(key.toLowerCase()) != -1 ? true : false
        } catch (error) {
            return false
        }
    }

    return (
        <div className="content">
            {
                page == 'create'
                    ?
                    <>
                        {back()}
                        <DHBCreateMember />
                    </>
                    :
                    page == "detail"
                        ?
                        <>
                            {back()}
                            <DHBMemberDetails data={dataProps} />
                        </>
                        :
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={() => setPage('create')}>
                                Thêm
                            </button>
                            <div className="function">
                                <div style={{ minWidth: "140px", height: "39px", margin: "10px 0px 0px 0px" }}>
                                    <Select
                                        placeholder={selectFilter}
                                        value={selectFilter}
                                        onChange={(e) =>
                                            setSelectFilter(e.value)
                                        }
                                        options={optionsFilter()}
                                    />
                                </div>
                                <Input
                                    className="input-filter"
                                    value={filter}
                                    onChange={(e) =>
                                        setFilter(e.target.value)
                                    }
                                />
                                <button
                                    className="btn btn-primary"
                                    onClick={() => filterr()}>
                                    Tìm
                                </button>
                            </div>
                            {table()}
                        </>
            }
        </div>
    )
}

export default DonHang