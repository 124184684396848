import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { Button, Modal } from 'react-bootstrap';
import LanguageService from '../../services/language.service';

import './CSS.css'

function LanguageDetails(props) {
    const data = props.data
    const [tool, setTool] = useState(data.tool)
    const [id, setId] = useState(data.id)
    const [key, setKey] = useState(data.key)
    const [vi, setVi] = useState(data.vi || '')
    const [en, setEn] = useState(data.en || '')

    useEffect(() => {
        //form load
    }, [])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    function createLang() {
        LanguageService.createLanguage(tool, key, vi, en)
            .then((result) => {
                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: result.data.message,
                        showConfirmButton: true,
                        timer: 1000
                    }).then(res => {
                        //window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }
            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function updateLang() {
        LanguageService.updateLanguage(tool, key, vi, en)
            .then((result) => {
                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cập nhập thành công',
                        showConfirmButton: true,
                        timer: 1000
                    }).then(res => {
                        //window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }
            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function deleteLang() {
        LanguageService.deleteLanguage(tool, key)
            .then((result) => {
                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Xóa ngôn ngữ thành công',
                        showConfirmButton: true,
                        timer: 1000
                    }).then(res => {

                        //window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }

            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }


    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    margin: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>

                <div style={{ width: "100vh", height: "100vh" }}>
                    <FormGroup>

                        <Label style={{ marginTop: "1rem" }} for="key">Key</Label>
                        <Input
                            value={key}
                            id="key"
                            onChange={(e) => { setKey(e.target.value) }}
                        />

                        <Label style={{ marginTop: "1rem" }} for="vi">Vietnamese</Label>
                        <Input
                            value={vi}
                            id="vi"
                            onChange={(e) => setVi(e.target.value)}
                        />

                        <Label style={{ marginTop: "1rem" }} for="en">English</Label>
                        <Input
                            value={en}
                            id="en"
                            onChange={(e) => setEn(e.target.value)}
                        />
                    </FormGroup>

                    <div style={{ marginTop: "30px" }}>
                        <Button className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => createLang()}>Thêm mới</Button>
                        <Button className="btn btn-warning" style={{ marginRight: "10px" }} onClick={() => updateLang()}>Sửa</Button>
                        <Button className="btn btn-danger" onClick={() => deleteLang()}>Xóa</Button>
                    </div>

                    <div style={{ float: "right", marginTop: "0.5rem" }}>
                        <div style={{ width: "100%" }}></div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default LanguageDetails