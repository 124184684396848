import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin";

class ToolService {

    getToolDatabase() {
        return axios.get(`${API_URL}/tool/database`, { headers: authHeader() });
    }

}

export default new ToolService();