import React, { useState, useEffect } from 'react'
import {
    Table,
    Input,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Col,
    Row,
    Card,
    CardFooter,
    CardBody,
    Label,
    FormGroup,
    CardHeader,
    CardTitle
} from "reactstrap";
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Select from 'react-select';

import updateService from 'services/update.service'
import toolService from 'services/tool.service'

function Orders() {
    const [lstTool, setLstTool] = useState([])
    const [tool, setTool] = useState('')
    const [toolName, setToolName] = useState('')
    const [plugin, setPlugin] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [id, setId] = useState('')
    const [typeFile, setTypeFile] = useState('Tải lên file')

    const [localPath, setLocalPath] = useState('')

    const [lstFolder, setLstFolder] = useState([])
    const [folderSelected, setFolderSelected] = useState('')

    const [lstFile, setLstFile] = useState([])
    const [fileSelected, setFileSelected] = useState('')

    const [version, setVersion] = useState('file')
    const [log, setLog] = useState('')

    const [selectedFile, setSelectedFile] = useState('')

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        toolService.getToolDatabase().then((result) => {
            setLstTool(result.data)

            loadPlugin(result.data[0].matool)
            setToolName(result.data[0].name)
        })
    }, [])

    function loadPlugin(tool) {
        setTool(tool)

        updateService.getPlugin(tool).then((result) => {
            setPlugin(result.data.data)
        })
    }

    function postPlugin() {
        var file = selectedFile
        if (typeFile == "Chọn từ server") {
            file = folderSelected + "/" + fileSelected
            setSelectedFile(file)
        }

        updateService.postPlugin(tool, typeFile, localPath, file).then((result) => {
            if (result.data.success) {
                updateService.getPlugin(tool).then((result) => {
                    setPlugin(result.data.data)
                })
            }

            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                showConfirmButton: true,
                timer: 1000
            })
        })
    }

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Server Path</th>
                        <th className="table-device-mobile">Local Path</th>
                        {/* <th className="table-device-mobile">MD5</th> */}
                        <th className="table-device-mobile">Updated At</th>
                        <th className='text-center'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }


    function loadFillData() {
        return plugin.map((data, key) => {
            return (
                <tr key={key}>
                    <td className="table-device-mobile">{data.url_v2}</td>
                    <td className="table-device-mobile">{data.path}</td>
                    {/* <td className="table-device-mobile">{data.md5}</td> */}
                    <td className="table-device-mobile"> {moment(data.createdAt).format('DD/MM/yyyy HH:mm')} </td>

                    <td className='text-center'>
                        <Button style={{ marginLeft: "10px" }} type="button" className="btn btn-danger " onClick={() => {

                            Swal.fire({
                                title: 'Xác nhận xóa ?',
                                text: `Điều này sẽ xóa bản ghi ${data.path} phần mềm ${toolName}`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Có, xóa bản ghi!'
                            }).then((result) => {
                                if (result.isConfirmed) {

                                    updateService.deletePlugin(data.tool, data.id, data.url_v2).then((result) => {
                                        if (result.data.success) {
                                            updateService.getPlugin(data.tool).then((result) => {
                                                setPlugin(result.data.data)
                                            })
                                        }
                            
                                        Swal.fire({
                                            icon: result.data.success ? 'success' : 'error',
                                            title: result.data.message,
                                            showConfirmButton: true,
                                            timer: 1000
                                        })
                                    })
                                }
                            })
                        }}>Xóa</Button>
                    </td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            <Row>
                <Col md="9">
                    <div className="card">
                        <div className="card-header">
                            <div className="p-2">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                    <DropdownToggle className="btn-secondary" caret>{toolName}</DropdownToggle>
                                    <DropdownMenu >
                                        {
                                            lstTool.map((data, key) => {
                                                return (
                                                    <DropdownItem onClick={() => { loadPlugin(data.matool); setToolName(data.name) }} key={key}>{data.name}</DropdownItem>
                                                )
                                            })
                                        }

                                        <DropdownItem onClick={() => { loadPlugin('shared'); setToolName("Shared") }} >Shared</DropdownItem>


                                        {/* <DropdownItem onClick={() => { loadPlugin('dhbreupyoutube'); setToolName("DHB Reup Youtube") }} >Reup Youtube</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbreupfacebook'); setToolName("DHB Reup Facebook") }} >Reup Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbreuptiktok'); setToolName("DHB Reup TikTok") }} >Reup TikTok</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbreupinstagram'); setToolName("DHB Reup Instagram") }} >Reup Instagram</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbstreampro'); setToolName("DHB Stream Pro") }} >Stream Pro</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbstreamfacebook'); setToolName("DHB Stream Facebook") }} >Stream Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbyoutubemanager'); setToolName("DHB Youtube Manager") }} >Youtube Manager</DropdownItem>
                                        <DropdownItem onClick={() => { loadPlugin('dhbgologinmanager'); setToolName("DHB GoLogin Manager") }} >GoLogin Manager</DropdownItem> */}

                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="card-body">
                            {table()}
                        </div>
                    </div>
                </Col>

                <Col md="3">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Thêm bảng ghi</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label>Nguồn dữ liệu</Label>
                                <Select
                                    placeholder={typeFile}
                                    value={typeFile}
                                    onChange={(e) => {
                                        setTypeFile(e.value)

                                        if (e.value == "Chọn từ server") {
                                            updateService.getExplorerFiles("").then((result) => {
                                                var result = result.data.data
                                                var lst = []
                                                for (let i = 0; i < result.length; i++) {
                                                    lst.push({ value: result[i], label: result[i] });
                                                }

                                                setLstFolder(lst)
                                                setFolderSelected("./update/shared/plugin")

                                                updateService.getExplorerFiles("./update/shared/plugin").then((result) => {
                                                    var result = result.data.data
                                                    var lst = []
                                                    for (let i = 0; i < result.length; i++) {
                                                        lst.push({ value: result[i], label: result[i] });
                                                    }

                                                    setLstFile(lst)
                                                    setFileSelected("")
                                                })
                                            })
                                        }
                                    }}
                                    options={
                                        [
                                            { value: 'Tải lên file', label: 'Tải lên file' },
                                            { value: 'Chọn từ server', label: 'Chọn từ server' }
                                        ]
                                    }
                                />

                                {
                                    typeFile == 'Tải lên file'
                                        ?
                                        <>
                                            <Label style={{ marginTop: "1rem" }}>Local Path</Label>
                                            <Input style={{ maxHeight: "100%" }}
                                                value={localPath}
                                                id="localPath"
                                                onChange={(e) => { setLocalPath(e.target.value) }}
                                            />

                                            <Label style={{ marginTop: "1rem" }}>File</Label>
                                            <Input
                                                type="file"
                                                id="file"
                                                onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                            />
                                        </>
                                        :
                                        <>
                                            <div style={{ paddingTop: "15px" }}>
                                                <Label>Thư mục</Label>
                                                <Select
                                                    placeholder={folderSelected}
                                                    value={folderSelected}
                                                    onChange={(e) => {
                                                        setFolderSelected(e.value)

                                                        updateService.getExplorerFiles(e.value).then((result) => {
                                                            var result = result.data.data
                                                            var lst = []
                                                            for (let i = 0; i < result.length; i++) {
                                                                lst.push({ value: result[i], label: result[i] });
                                                            }

                                                            setLstFile(lst)
                                                            setFileSelected("")
                                                        })
                                                    }}
                                                    options={lstFolder}
                                                />
                                            </div>

                                            <div style={{ paddingTop: "15px" }}>
                                                <Label>Tập tin</Label>
                                                <Select
                                                    placeholder={fileSelected}
                                                    value={fileSelected}
                                                    onChange={(e) => {
                                                        setFileSelected(e.value)
                                                    }}
                                                    options={lstFile}
                                                />
                                            </div>

                                            <Label style={{ marginTop: "1rem" }}>Local Path</Label>
                                            <Input style={{ maxHeight: "100%" }}
                                                value={localPath}
                                                id="localPath"
                                                onChange={(e) => { setLocalPath(e.target.value) }}
                                            />
                                        </>
                                }
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <Button
                                // disabled={!(localPath && selectedFile)}
                                type="button"
                                className="btn btn-success"
                                onClick={postPlugin}
                            >
                                Thêm mới
                            </Button>


                            {/* <Button
                                disabled={!(id && version && log)}
                                style={{ marginLeft: "10px" }}
                                type="button"
                                className="btn btn-warning"
                                onClick={putPlugin}
                            >
                                Cập nhập
                            </Button> */}

                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default Orders